import { EventEmitter, Component, Output } from '@angular/core';

@Component({
  selector: 'app-showcase',
  template: `
    <div class="showcase">
          <div class="showcase-container">
              <div class="showcase-content">
                    <div class="image-container">
                        <div class="first-image showcase-image"></div>
                        <div class="second-image showcase-image"></div>
                        <div class="third-image showcase-image"></div>
                        <div class="fourth-image showcase-image"></div>
                    </div>
                    <div class="showcase-text-container">
                        <p class="showcase-text">Willkommen bei der DG <span class="bold-company-name">AUTOTECHNIC</span> - Freundlich - Fair - Kompetent!</p>
                        <button (click)="onWhyUsBtnClick()" #whyUsBtn class="why-us-btn btn">Warum uns wählen?</button>
                    </div>
              </div>
          </div>
      </div>
    `,
  styleUrls: ['./showcase.component.css']
})
export class ShowcaseComponent {
    @Output() public onWhyBtnClick: EventEmitter<any> = new EventEmitter();
    public onWhyUsBtnClick(): void {
        this.onWhyBtnClick.emit();
    }
}
