export interface cardItems {
    carClass?: string;
    name?: string;
    description?: string;
    selected?: boolean;
    display?: boolean;
};

export const items: cardItems[] = [
    {
        name: "Mario Muffel",
        description: `Wer eine sehr Gute Kfz Werkstatt sucht, ist hier sehr gut aufgehoben. Der Preis war im Vergleich zu anderen Werkstätten erheblich weniger. Die Leistung und die Kompetenz ist unschlagbar. Ein rund um Service gab es noch obendrauf und mir wurde gesagt, was in den nächsten Monaten anfallen könnte. Fazit: Empfehlenswert !!!`,
        carClass: "mazda",
        display: true
    }, {
        name: "Katarzyna Pronin",
        description: `Ich habe nur sehr gute Erfahrung mit dem Mechaniker gemacht. Er ist immer freundlich und hilfsbereit. Die Termine zur Reparatur passen sehr ins Alltag. Die Wartezeiten sind nicht lang. Zur Not wird ein Mietwagen angeboten. Das Preis Leistungs Verhältnis ist toll. Mit allen Fragen und Auto Problemen, großen oder kleinen, nur dahin.`,
        carClass: "honda",
        display: true,
        selected: true
    }, {
        name: "Daniel Tolksdorf",
        description: "Ich bin sehr positiv überrascht über die Herzlichkeit und Hilfsbereitschaft. Die Arbeit die dort an meinen Fahrzeugen gemacht wurden ,sind schnell und zur vollsten Zufriedenheit zu einen sehr guten Preis erfolgt . Ich kann diese Werkstatt nur empfehlen. 5 Sterne.",
        carClass: "nissan",
        display: true
    }, {
        name: "Dimo Dimitrov",
        description: "Wir haben die GD Autotechnik einige Male besucht, um jährlich Routinefahrzeuge zu überprüfen, Verbrauchsmaterialien auszutauschen, TÜV- und Karosseriereparaturen durchzuführen. Wir sind sehr zufrieden mit dem professionellen Ansatz der GD Autotechnik, dem schnellen Service, der guten Kommunikation und nicht zuletzt den angemessenen Preisen. Empfohlen.",
        carClass: "audi",
        display: false
    }, {
        name: "Roland Meier",
        description: "Gute Handwerksarbeit. Das Team ist sehr freundlich und haben immer ein offenes Ohr für die Sorgen der Kunden. Es werden auch mal zwischendurch kleine Arbeiten ohne Termin gemacht. Top!",
        carClass: "renault",
        display: false
    }, {
        name: "Tommy Peefadoppelf",
        description: `Ein Dienstleister, dem ich 5 Sterne geben muss!
        Mein Multivan T6 ist mit dem Verdacht auf Motorschaden bei Daniel gelandet. Daniel hat die Ursache unglaublich schnell gefunden und hat den Wagen wieder instand gesetzt. Selten habe ich einen Dienstleister als so freundlich, hilfsbereit und kompetent empfunden. Die komplexe Reparatur wurde schnellstmöglich durchgeführt und ich wurde immer auf dem Laufenden gehalten. An dieser Stelle nochmal vielen Dank für diesen 5Sterne-Service. Solltet ihr Probleme mit eurem KFZ haben ist diese Adresse unbedingt aufzusuchen!!!`,
        carClass: "bmw",
        display: false
    }
];
