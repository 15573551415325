import { Component } from '@angular/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

@Component({
  selector: 'app-root',
  template: `
    <div class="wavy">
      <span style="--i:1">D</span>
      <span style="--i:2">E</span>
      <span style="--i:3">M</span>
      <span style="--i:4">N</span>
      <span style="--i:5">Ä</span>
      <span style="--i:6">C</span>
      <span style="--i:7">H</span>
      <span style="--i:8">S</span>
      <span style="--i:9">T</span>
    </div>
  `,
  styles: [
    `
    `,
  ],
})
export class AppComponent {
  constructor() {}
}
