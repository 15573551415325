import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NavbarComponent } from './util-comps/navbar/navbar.component';
import { ShowcaseComponent } from './home-page/showcase/showcase.component';
import { WhyUsComponent } from './home-page/why-us/why-us.component';
import { OurServicesComponent } from './home-page/our-services/our-services.component';
import { CarRangeComponent } from './home-page/car-range/car-range.component';
import { TestimonialsComponent } from './home-page/testimonials/testimonials.component';
import { FooterComponent } from './util-comps/footer/footer.component';
import { ServicesComponent } from './services/services.component';
import { TestimonialCardComponent } from './home-page/testimonials/testimonial-card.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { cookieConfig } from '../cookie-config';
import { AgmCoreModule } from '@agm/core';

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent, NavbarComponent,
        ShowcaseComponent,
        WhyUsComponent,
        OurServicesComponent,
        CarRangeComponent,
        TestimonialsComponent,
        FooterComponent,
        ServicesComponent,
        TestimonialCardComponent,
        GalleryComponent,
        AboutUsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgcCookieConsentModule.forRoot(cookieConfig),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDgMkjSpGvjLf1G91zGbWV3USF3uxS4p3A'
          })
      
      ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
