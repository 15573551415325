import { NgcCookieConsentConfig } from "ngx-cookieconsent";

export const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: "localhost"
    },
    palette: {
        popup: {
            background: "#000"
        },
        button: {
            background: "#f1d600"
        }
    },
    theme: "edgeless",
    type: "opt-in",
    layout: "my-custom-layout",
    layouts: {
        "my-custom-layout": "{{messagelink}}{{compliance}}"
    },
    elements: {
        messagelink: `
        <span id="cookieconsent:desc" class="cc-message">{{message}} 
            <a aria-label="learn more about cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a> and our
            <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>
        </span>
        `
    },
    content: {
        message:
            "By using our site, you acknowledge that you have read and understand our ",
    
        cookiePolicyLink: "Cookie Policy",
        cookiePolicyHref: "https://www.iubenda.com/privacy-policy/20081673/cookie-policy.",
    
        privacyPolicyLink: "Privacy Policy",
        privacyPolicyHref: "https://www.iubenda.com/privacy-policy/20081673",
    }
  };