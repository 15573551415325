import { Component } from '@angular/core';

@Component({
    selector: 'app-home-page',
    template: `
        <app-navbar [activeLinkIndex]="0"></app-navbar>
        <app-showcase (onWhyBtnClick)="onWhyBtnClick()"></app-showcase>
        <app-why-us></app-why-us>
        <app-our-services></app-our-services>
        <app-car-range></app-car-range>
        <app-testimonials></app-testimonials>
        <app-footer></app-footer>
    `,
    styles: [``]
})
export class HomePageComponent  { 

    constructor() {}

    public onWhyBtnClick(): void {
        const whyUsRect = document.querySelector('.row.why-us').getBoundingClientRect();
        const whyUsScrollHeight = (whyUsRect.top + window.scrollY) - 100;

        window.scrollTo({ behavior: 'smooth', top: whyUsScrollHeight });
    }

}
