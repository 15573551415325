import { AfterViewInit, Component, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { cardItems, items } from './card-data';
import { TestimonialCardComponent } from './testimonial-card.component';

enum DIRECTIONS {
    LEFT = 'left',
    RIGHT = 'right'
};
@Component({
  selector: 'app-testimonials',
  template: `
    <div class="row testimonials">
        <h4 class="desc-header">WAS SAGEN DIE LEUTE</h4>
        <app-testimonial-cards #cardsWrapper [items]="items"></app-testimonial-cards>
    </div>
  `,
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements AfterViewInit, OnDestroy {
    @ViewChild('cardsWrapper') public cardsWrapper: TestimonialCardComponent;
    
    public items: cardItems[] = items;

    private subs: Subscription = new Subscription();

    constructor(private renderer: Renderer2, private elem: ElementRef) { }

    ngAfterViewInit(): void {
        this.subs.add(
            this.renderer.listen(this.elem.nativeElement, 'click', event => this.onArrowClick(event))
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private onArrowClick(event: any): void {
        const target: HTMLElement = event.target;
        const isArrowClicked: boolean = target.className.includes('left-arrow') || target.className.includes('right-arrow');

        if (isArrowClicked) {
            const isArrowLeftClicked: boolean = target.className.includes('left-arrow');
            const dir = isArrowLeftClicked ? DIRECTIONS.LEFT : DIRECTIONS.RIGHT;

            this.cardsWrapper.onArrowClick(dir);
        }
    }
}
