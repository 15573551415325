import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-why-us',
    template: `
        <div class="row why-us">
            <h2 class="desc-header">WARUM UNS WÄHLEN?</h2>
            <div class="desc-figures">
                <ul class="figures">
                    <li class="figures-container">
                        <div class="figure"></div>
                        <div class="icon-figures bi bi-tools"></div>
                        <div class="desc-figure">
                            <h4>Professionelle Arbeit</h4>
                        </div>
                        <div class="desc-description">
                            <p>Wir nehmen unsere Aufgabe sehr ernst und sind bestrebt, Ihnen und Ihrem vierrädrigen Freund die besten Lösungen zu bieten. Wir erklären, wo die Probleme liegen und wie wir sie angehen.</p>
                        </div>
                    </li>
                    <li class="figures-container">
                        <div class="figure"></div>
                        <div class="icon-figures bi bi-stopwatch"></div>
                        <div class="desc-figure">
                            <h4 >Wir arbeiten schnell</h4>
                        </div>
                        <div class="desc-description">
                            <p>Wir sind Profis, die wissen, was sie tun. Pünktliches Arbeiten ohne zusätzliche Schritte ist unser fester Glaube und unser Ideal.</p>
                        </div>
                    </li>
                    <li class="figures-container">
                        <div class="figure"></div>
                        <div class="icon-figures bi bi-person-check-fill"></div>
                        <div class="desc-figure">
                            <h4>Zufriedenheit</h4>
                        </div>
                        <div class="desc-description">
                            <p >Wir sind Profis, die präzise, zuverlässig und pünktlich arbeiten und gleichzeitig freundlich und leidenschaftlich bei der Sache sind.</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    `,
    styleUrls: ['./why-us.component.css']
})
export class WhyUsComponent implements OnInit {

    constructor() { /**  */}

    ngOnInit(): void { /**  */}
}
