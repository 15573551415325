import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-car-range',
  template: `
      <div class=" row car-range">
        <h4 class="desc-header">FAHRZEUGE DIE WIR SERVIEREN</h4>
        <div class="vehicles-list">
            <div class="vehicle-brand-list-items">
                <div class="brand-items">
                    <div class="brand-item">
                        <div class="brand honda"></div>
                        <h4 class="brand-name">Honda</h4>
                   </div>
                    <div class="brand-item">
                        <div class="brand nissan"></div>
                        <h4 class="brand-name">Nissan</h4>
                   </div>
                    <div class="brand-item">
                        <div class="brand mitsubishi"></div>
                        <h4 class="brand-name">Mitsubishi</h4>
                   </div>
                    <div class="brand-item">
                        <div class="brand mazda"></div>
                        <h4 class="brand-name">Mazda</h4>
                   </div>
                    <div class="brand-item">
                        <div class="brand mercedes-benz"></div>
                        <h4 class="brand-name">Mercedes-Benz</h4>
                   </div>
                    <div class="brand-item">
                        <div class="brand bmw"></div>
                        <h4 class="brand-name">BMW</h4>
                   </div>
                    <div class="brand-item">
                       <div class="brand audi"></div>
                        <h4 class="brand-name">Audi</h4>
                   </div>
                    <div class="brand-item">
                        <div class="brand renault"></div>
                        <h4 class="brand-name">Renault</h4>
                    </div>
                    <div class="brand-item">
                        <div class="brand ford"></div>
                        <h4 class="brand-name">Ford</h4>
                    </div>
                    <div class="brand-item">
                        <div class="brand porsche"></div>
                        <h4 class="brand-name">Porsche</h4>
                    </div>
                    <div class="brand-item">
                        <div class="brand volkswagen"></div>
                        <h4 class="brand-name">Volkswagen</h4>
                    </div>
                    <div class="brand-item">
                        <div class="brand lexus"></div>
                        <h4 class="brand-name">Lexus</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
  `,
  styleUrls: ['./car-range.component.css']
})
export class CarRangeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
