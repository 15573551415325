export interface CardData {
    icon?: string;
    header?: string;
    text?: string;
};

export interface ServiceData {
    name?: string;
    subCategories?: string[];
    text?: string
};

export const arrowDown = '../assets/images/service-card-icons/arrow-down.png'
export const arrowUp = '../assets/images/service-card-icons/arrow-up.png'

export const cardData: CardData[] = [
    {
        icon: '../assets/images/service-card-icons/car-service-icon.png',
        header: 'Autowerkstatt',
        text: 'Wir führen unsere Routinekontrollen sorgfältig durch. Alles ist enthalten, von Bremsen, Öl, Filtern, Motorriemen bis hin zur Prüfung auf Verschleißteile.'
    }, {
        icon: '../assets/images/service-card-icons/car-repair-icon.png',
        header: 'Autoreparatur',
        text: 'Wir bieten eine sehr gründliche Reparatur, Umbau, Aufbereitung von Fahrzeugen oder Teilen an. Inklusive Unfallservice, Lackierung und Dampfreinigung.'
    }, {
        icon: '../assets/images/service-card-icons/car-cleaning-icon.png',
        header: 'Autopolitur',
        text: 'Wir kennen unser Handwerk, wenn es um das Polieren und Wachsen Ihres Autos geht. Von Kratzern und Unvollkommenheiten bis hin zu Lackierarbeiten sind Sie bei uns genau richtig.'
    }
];

export const serviceData: ServiceData[]  = [
    {
        name: 'Service / Inspektion',
        subCategories: [
            'Nach Herstellervorgaben',
            'Mit Eintragung in das digitale Serviceheft',
            'Erhaltung der Herstellergarantie'
        ],
        text: 'Durch den routinemäßigen Autoservice stellen wir sicher, dass Ihr Auto fahrbereit ist.'
    }, {
        name: 'Fahrwerk, Bremse, Achsvermessung',
        text: 'Die Bremsen sind das Wichtigste an Ihrem Auto! Wir stellen sicher, dass sie ordnungsgemäß funktionieren, indem wir Ihre Bremsen, Federung und Achsvermessung überprüfen.'
    }, {
        name: 'Reparatur von Unfallschäden',
        subCategories: [
            'Karrosseriearbeiten',
            'Lackierarbeiten',
            'Erhaltung der Herstellergarantie'
        ],
        text: 'Ganz gleich, was den Unfallschaden verursacht hat – Kollision, Unfall oder ein anderes unerwartetes Ereignis – wir reparieren Ihr Auto und lassen es wie neu aussehen.'
    }, {
        name: 'HU/AU',
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores nisi totam suscipit velit architecto? Voluptates!'
    }, {
        name: 'Allradsysteme',
        text: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dolores nisi totam suscipit velit architecto? Voluptates'
    }, {
        name: 'Motoren und Getriebeinstandsetzung',
        subCategories: ['Automatik', 'CVT', 'DSG', 'herkömmliche Schaltgetriebe'],
        text: 'Unser Wissen über Autos ist tiefgreifend. Aus diesem Grund gehen Reparaturen an Motoren und Getrieben nicht an uns vorbei. Wir werden das Problem diagnostizieren und beheben.'
    }, {
        name: 'Tuning und Umbaumaßnahmen',
        subCategories: ['Kompetenz in Turbosystemen', 'Kompetenz in Transportern und Klein-LKW', 'Leistungssteigerung'],
        text: 'Unsere Basis ist ausgestattet und in der Lage, Ihr Auto zu tunen und für alle Leistungsanforderungen zu optimieren, die Sie benötigen. Handling, Kraftstoffverbrauch oder sanfteres Ansprechverhalten – bei uns sind Sie an der richtigen Adresse.'
    },
    {
        name: 'Firmenkundenkompetenz',
        subCategories: ['Flottenpflege und Schnellservice', 'UVV Abnahme'],
        text: 'Wir sind ausgebildete Fachkräfte mit der Fähigkeit, Ihre Serviceanforderungen und Erwartungen direkt oder indirekt zu erfüllen und zu übertreffen.'
    }
];