import {
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    OnDestroy,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { serviceData, ServiceData } from '../../common/service-data';

@Component({
  selector: 'app-footer',
  template: `
    <div class="row">
        <div class="footer-items">
            <div class="svg-wave">
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
                    <path
                        d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z">
                    </path>
                </svg>
            </div>
            <div class="footer-content">
                <div class="contact">
                    <ul class="contact-list">
                        <h4 class="content-header">Kontakte</h4>
                         
                        <li class="contact-item"> <span class="contact-title">Stadt / Land:</span> Hamburg, Germany</li>
                        <li class="contact-item"> <span class="contact-title">Addresse:</span> Haldesdorfer Str. 109 22179</li>
                        <li class="contact-item"> <span class="contact-title">Telefon:</span> +49 40 55009571</li>
                        <li class="contact-item"> <span class="contact-title">Email:</span> info@auto-technic.de</li>
                    </ul>
                </div>
                <div #footerServices class="footer-services">
                    <h4 class="content-header">Unsere Dienstleistungen</h4>
                    <ul class="footer-services-list">
                        <div *ngFor="let service of serviceData; let i = index" class="footer-services-item" [id]="i">
                            <li class="service-text">{{ service.name }}</li>
                        </div>
                    </ul>
                </div>
                <div class="working-hours">
                    <h4 class="content-header">Öffnungszeiten</h4>
                    <ul class="hours-list">
                        <li class="hours-item"> <span class="day-title">Montag:</span> 08:30am - 18:00pm</li>
                        <li class="hours-item"> <span class="day-title">Dienstag:</span> 08:30am - 18:00pm</li>
                        <li class="hours-item"> <span class="day-title">Mittwoch:</span> 08:30am - 18:00pm</li>
                        <li class="hours-item"> <span class="day-title">Donnerstag:</span> 08:30am - 18:00pm</li>
                        <li class="hours-item"> <span class="day-title">Freitag:</span> 08:30am - 18:00pm</li>
                        <li class="hours-item"> <span class="day-title">Samstag:</span> Geschlossen</li>
                        <li class="hours-item"> <span class="day-title">Sonntag:</span> Geschlossen</li>
                    </ul>
                </div>
            </div>
            <div class="svg-wave bottom-wave">
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
                    <path
                        d="M0.00,92.27 C216.83,192.92 304.30,8.39 500.00,109.03 L500.00,0.00 L0.00,0.00 Z">
                    </path>
                </svg>
                </div>
            <p class="icons8">Icons provided by <a target="_blank" href="https://icons8.com">Icons8</a></p>
        </div>
    </div>
  `,
  styleUrls: ['./footer.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements AfterViewInit, OnDestroy {
    @HostBinding('class.footer') public hostClass: boolean = true;
    @ViewChild('footerServices') public footerServices: ElementRef;
    
    public serviceData: ServiceData[] = serviceData;
    private subs: Subscription = new Subscription();

    constructor(private renderer: Renderer2) { }

    public ngAfterViewInit(): void {
        this.subs.add(this.renderer.listen(this.footerServices.nativeElement, 'click', event => {
            const target = event.target;

            const serviceWrapper = target.className.includes('footer-services-item');
            const serviceText = target.className.includes('service-text');
            const toolIcon = target.className.includes('tool-icon');

            if (serviceWrapper | serviceText | toolIcon) {
                const serviceItemParent = this.closest('footer-services-item', target);
                const serviceId = serviceItemParent ? serviceItemParent.getAttribute('id') : target.getAttribute('id');

                window.localStorage.setItem('serviceItemId', serviceId);
                window.location.href = '/leistungen';
            }
        }));
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public closest(target, elem): any {
        let found;
        while (!elem.className.includes(target)) {
            found = elem = elem.parentElement;
        }

        return found;
    }
}
