export interface GalleryCards {
    src?: string;
    alt?: string;
};
export const galleryCards: GalleryCards[] = [
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' },
    { src: '/../../../assets//images/straps-hoses.jpg', alt: 'engine' }
]
