import { AfterViewInit, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { cardItems } from './card-data';

@Component({
    selector: 'app-testimonial-cards',
    template: `
        <ng-container *ngFor="let item of items; index as idx">
            <div [ngClass]="{ 'selected': item.selected, 'fade': animation }" *ngIf="item.display" class="testimonial-item" [id]="idx">
                <div class="chevron-icon left-arrow"></div>
                <div class="rating-stars">
                    <img src="../../assets/images/icons/icons8-star-filled-48.png" alt="rating star"/>
                    <img src="../../assets/images/icons/icons8-star-filled-48.png" alt="rating star"/>
                    <img src="../../assets/images/icons/icons8-star-filled-48.png" alt="rating star"/>
                    <img src="../../assets/images/icons/icons8-star-filled-48.png" alt="rating star"/>
                    <img src="../../assets/images/icons/icons8-star-filled-48.png" alt="rating star"/>
                </div>
                <h4 class="testimonial-name desc-header"> {{ item.name }} </h4>
                <p class="testimonial-desc"> {{ item.description }} </p>
                <div class="chevron-icon right-arrow"></div>
            </div>
        </ng-container>
    `,
    styleUrls: ['./testimonials.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class TestimonialCardComponent implements AfterViewInit {
    @HostBinding('class.testimonial-items') public hostClasses: boolean = true;
    @Input() public items: cardItems[];

    public animation: boolean = false;

    private tmpItems: cardItems[];
    private selectedItemIndex: number = 1;
    private canClickArrow: boolean = true;
    private direction: string;
    private arrowTimeout: ReturnType<typeof setTimeout>;

    constructor() { }

    ngAfterViewInit(): void {
        this.tmpItems = this.items;
    }

    public onArrowClick(direction: string): void {
        this.canClickArrow = true;
        this.arrowTimeout = setTimeout(() => {
            this.onChangeDisplayItem(this.direction)

            this.canClickArrow = false;
            this.animation = false;
        }, 500);
                
        this.direction = direction;
        this.animation = true;
    }

    private onChangeDisplayItem(direction): void {
        if (this.canClickArrow) {
            this.items = [...this.tmpItems];
    
            if (direction === 'left') {
                this.switchLeft();
            } else {
                this.switchRight();
            }
        }

        clearTimeout(this.arrowTimeout);
    }

    private switchLeft(): void {
        if (this.selectedItemIndex === 0) {
            this.items[1].display = false;
        } else if (this.selectedItemIndex === this.items.length - 1) {
            this.items[0].display = false;
        } else {
            this.items[this.selectedItemIndex + 1].display = false;
        }

        this.items[this.selectedItemIndex].selected = false;
        this.selectedItemIndex -= 1;
        
        if (this.selectedItemIndex < 0) {
            this.items[this.items.length - 2].display = true;
            this.items[this.items.length - 1].selected = true;

            this.selectedItemIndex = this.items.length - 1;
            
            [this.items[0], this.items[this.items.length - 1]] = [this.items[this.items.length - 1], this.items[0]];
            [this.items[0], this.items[this.items.length - 2]] = [this.items[this.items.length - 2], this.items[0]];
            

        } else if (this.selectedItemIndex < 1) {
            this.items[this.items.length - 1].display = true;
            this.items[this.selectedItemIndex].selected = true;

            [this.items[0], this.items[this.items.length - 1]] = [this.items[this.items.length - 1], this.items[0]];
            [this.items[1], this.items[this.items.length - 1]] = [this.items[this.items.length - 1], this.items[1]];
        } else {
            this.items[this.selectedItemIndex].selected = true;
            this.items[this.selectedItemIndex - 1].display= true;   
        }
    }

    private switchRight(): void {
        if (this.selectedItemIndex === 0) {
            this.items[this.items.length - 1].display = false;
        } else {
            this.items[this.selectedItemIndex - 1].display = false;
        }

        this.items[this.selectedItemIndex].selected = false;       
        this.selectedItemIndex += 1;
        
        if (this.selectedItemIndex > this.items.length - 1) {
            this.items[1].display = true;
            this.items[0].selected = true;
            this.selectedItemIndex = 0;

            [this.items[0], this.items[this.items.length - 1]] = [this.items[this.items.length - 1], this.items[0]];
            [this.items[1], this.items[this.items.length - 1]] = [this.items[this.items.length - 1], this.items[1]];
        } else if (this.selectedItemIndex > this.items.length - 2) {
            this.items[0].display = true;
            this.items[this.selectedItemIndex].selected = true;

            [this.items[0], this.items[this.items.length - 1]] = [this.items[this.items.length - 1], this.items[0]];
            [this.items[0], this.items[this.items.length - 2]] = [this.items[this.items.length - 2], this.items[0]];
        } else {
            this.items[this.selectedItemIndex].selected = true;
            this.items[this.selectedItemIndex + 1].display = true;
        }
    }
}
