import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-our-services',
  template: `
      <div class="row services">
        <h2 class="desc-header">UNSERE DIENSTLEISTUNGEN</h2>
        <p class="desc">Egal ob Altagsfahrzeug oder Rennwagen mit Straßenzulassung, Oldtimer oder Neuwagen, bei uns erwartet Sie beste Qualität sowie ein sehr persönlicher Umgang</p>
        <div #serviceItems class="service-items">
            <div class="service-item">
                <div class="service-image oil"></div>
                <div class="service-desc">
                    <h4 class="service-item-desc">Schmiermittel, Öl und Filter</h4>
                </div>
            </div>
            <div class="service-item">
                <div class="service-image engine"></div>
                <div class="service-desc">
                    <h4 class="service-item-desc">Auto Motor Diagnose</h4>
                </div>
            </div>
            <div class="service-item">
                <div class="service-image belts"></div>
                <div class="service-desc">
                    <h4 class="service-item-desc">Riemen und Schläuche</h4>
                </div>
            </div>
        </div>
        <div class="service-button">
            <button (click)="onAllServicesClick()" #allServices class="btn" type="button">Alle Leistungen ansehen</button>
        </div>
    </div>
  `,
  styleUrls: ['./our-services.component.css']
})
export class OurServicesComponent implements AfterViewInit, OnDestroy{
    @ViewChild('serviceItems') public serviceItems: ElementRef;

    public subs: Subscription = new Subscription();

    constructor(private renderer: Renderer2) { }

    ngAfterViewInit(): void {
        const serviceItemsElem = this.serviceItems.nativeElement;

        this.subs.add(this.renderer.listen(serviceItemsElem, 'click', event => this.onServiceItemsClick(event)))
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public onAllServicesClick(): void {
        window.location.href = '/leistungen';
    }

    private onServiceItemsClick(event: any): void {
        const target = event.target;

        if (target.className.includes('service-image') || target.className.includes('service-item-desc')) {
            this.onAllServicesClick();
        }
    }
}
