import { AfterViewInit, Component, ElementRef, NgZone, QueryList, Renderer2, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
    arrowDown,
    arrowUp,
    CardData,
    cardData,
    ServiceData,
    serviceData
} from '../common/service-data';

@Component({
    selector: 'app-services',
    template: `
        <app-navbar [activeLinkIndex]="1"></app-navbar>
        <div class="row showcase-text">
            <h4 class="desc-header icons8-car-service">WIR KÖNNEN FÜR SIE TUN</h4>
        </div>
        <div class="card-services-container row">
            <div class="car-service-cards">
                <div *ngFor="let card of carServiceCards" class="car-service-card">
                    <div class="card-image">
                        <img [src]="card.icon" [alt]="card.header" />
                    </div>
                    <h4>{{ card.header }}</h4>
                    <p>{{ card.text }}</p>
                </div>
            </div>
        </div>
        <div class="services-container row">
            <h4 class="desc-header">LEISTUNGSLISTE</h4>
            <div #serviceList class="service-list">
                <div #serviceCards *ngFor="let service of services; index as i" [ngClass]="{'chosen-card': cardToShow === i}" class="service-item">
                    <h4 class="service-header">{{ service.name }}</h4>
                    <img type="button" [id]="i" class="arrow-image" [src]="arrowDown" alt="arrow down" />
                    <div *ngIf="cardToShow === i" class="service-details">
                        <p class="service-detail-text"> {{ service.text}} </p>
                        <div *ngIf="service.subCategories" class="sub-categories">
                            <ul class="sub-categories-items">
                                <li *ngFor="let category of service.subCategories" class="sub-categories-item">
                                    <span class="check-icon"></span>
                                    <span>{{ category }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    `,
    styleUrls: ['./services.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ServicesComponent implements AfterViewInit {
    @ViewChild('serviceList') public serviceList: ElementRef;
    @ViewChildren('serviceCards') public serviceCards: QueryList<any>;

    public carServiceCards: CardData[] = cardData;
    public services: ServiceData[] = serviceData;
    public arrowDown: string = arrowDown;
    public arrowUp: string = arrowUp;
    public cardToShow: number;
    public cardToHide: number;
    private subs: Subscription = new Subscription();

    constructor(private renderer: Renderer2, private zone: NgZone) { }

    public ngAfterViewInit(): void {
        this.expandOnOutsideService();
    
        this.subs.add(
            this.renderer.listen(this.serviceList.nativeElement, 'click', (event) => this.onArrowClick(event))
        );
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public onArrowClick(event: any): void {
        const target = event.target;

        if (target.className.includes('arrow-image')) {
            const id = parseInt(target.getAttribute('id'));
            const serviceCards = this.serviceCards.toArray();
            const parent = this.closest('service-item', target);

            if (this.cardToShow !== id) {
                this.cardToHide = this.cardToShow;
                this.cardToShow = id;

                this.renderer.setAttribute(target, 'src', this.arrowUp);
                this.renderer.setAttribute(target, 'alt', 'Arrow up');

                setTimeout(() => {
                    const serviceDetailsHeight = target.nextElementSibling.getBoundingClientRect().height;
                    this.renderer.setStyle(parent, 'height', `${50 + serviceDetailsHeight}px`)
                });

                setTimeout(() => {
                    const serviceDetail = target.nextElementSibling;
                    this.renderer.setStyle(serviceDetail, 'visibility', 'visible');
                }, 500);

                if (this.cardToHide >= 0) {
                    const prevTarget = serviceCards[this.cardToHide].nativeElement.querySelector('.arrow-image');
                    const prevParent = this.closest('service-item', prevTarget);

                    this.renderer.removeStyle(prevParent, 'height');
                    this.renderer.setAttribute(prevTarget, 'src', this.arrowDown);
                    this.renderer.setAttribute(prevTarget, 'alt', 'Arrow down');   
                }

                return;
            }

            this.cardToShow = -1;

            this.renderer.removeStyle(parent, 'height');
            this.renderer.setAttribute(target, 'src', this.arrowDown);
            this.renderer.setAttribute(target, 'alt', 'Arrow down');   
        }
    }

    public closest(target: string, elem: HTMLElement): any {
        let found;
        while (!elem.className.includes(target)) {
            found = elem = elem.parentElement;
        }

        return found;
    }

    private expandOnOutsideService(): void {
        const serviceItemId = window.localStorage.getItem('serviceItemId');

        if (serviceItemId) {
            this.zone.onStable.pipe(take(1)).subscribe(() => {
                const cardToExpand = this.serviceCards.toArray()[serviceItemId]?.nativeElement;
                const cardExpandArrow = cardToExpand?.querySelector('.arrow-image');
                
                window.scrollTo({ behavior: 'auto', top: 220 });
                cardExpandArrow?.click();
    
                window.localStorage.removeItem('serviceItemId');
            });
        }
    }
}
