import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navbar',
  template: `
    <div class="navbar">
      <div class="nav-items">
        <div #logo class="logo-image">
          <img src="../../assets/images/company-logo.png" alt="company logo" />
        </div>
        <span class="phone-number">
          <img
            src="../../assets/images/icons/icons8-telephone-50.png"
            alt="company logo"
          />
          <span>+49 40 55009571</span>
        </span>
        <div #breadcrumbs class="breadcrumbs">
          <div class="breadcrumbs-icon"></div>
          <div #linksWrapper class="breadcrumbs-wrapper">
            <a class="breadcrumb-item">Start</a>
            <a class="breadcrumb-item">Leistungen</a>
            <a class="breadcrumb-item">Kontakt</a>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements AfterViewInit, OnDestroy {
  @HostBinding('class.navbar-wrapper') public hostClass: boolean = true;

  @ViewChild('linksWrapper') public linkWrapper: ElementRef;
  @ViewChild('breadcrumbs') public breadcrumbs: ElementRef;
  @ViewChild('logo') public logo: ElementRef;

  @Input() public activeLinkIndex: number;

  private subs: Subscription = new Subscription();

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const links = this.linkWrapper.nativeElement.children;
    const activeLink = links[this.activeLinkIndex];

    this.renderer.addClass(activeLink, 'selected-item');

    this.subs.add(this.renderer.listen(this.logo.nativeElement, 'click', () => {
      window.location.href = '/';
    }));

    this.subs.add(
      this.renderer.listen(this.breadcrumbs.nativeElement, 'click', (event) => {
        const target = event.target;
        let link: string = event.target.innerText.toLowerCase();

        if (target.className.includes('breadcrumbs-icon') && window.innerWidth <= 850) {
          const action = !target.className.includes('opened')
            ? 'addClass'
            : 'removeClass';

          this.renderer[action](target, 'opened');
          this.renderer[action](this.linkWrapper.nativeElement, 'opened');
        }

        if (
          !target.className.includes('breadcrumbs-icon') &&
          !target.className.includes('breadcrumbs-wrapper')
        ) {
          if (link === 'start') {
            link = '';
          }

          window.location.href = `${link}`;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
