import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { galleryCards, GalleryCards } from './gallery-cards-data';

@Component({
    selector: 'app-gallery',
    template: `
        <app-navbar [activeLinkIndex]="2"></app-navbar>
        <div class="showcase-text row">
            <h2 class="desc-header">GALERIE</h2>
        </div>
        <div class="gallery-cards">
            <div #cardsWrapper class="card-items">
                <div *ngFor="let card of cards; index as i" class="card-item" [id]="i">
                    <img class="card-image" [src]="card.src" [alt]="card.alt" />
                    <div class="expand-arrows">
                        <span type="button" role="button" class="expand-icon bi bi-arrows-angle-expand"></span>
                    </div>
                </div>
            </div>
        </div>
        <app-footer></app-footer>

        <div *ngIf="showDialog" #dialog class="dialog">
            <div class="overlay"></div>
            <div class="card-wrapper">
                <div class="close-btn">
                    <button (click)="showDialog = false" type="button" class="bi bi-x"></button>
                </div>
                <img #dialogImg [src]="dialogImage.src" [alt]="dialogImage.alt" />
            </div>
        </div>
    `,
    styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements AfterViewInit, OnDestroy {
    @HostBinding('class.gallery-wrapper') public hostClass: boolean = true;

    @ViewChild('dialog') public dialog: ElementRef;
    @ViewChild('dialogImg') public dialogImg: ElementRef;
    @ViewChild('cardsWrapper') public cardsWrapper: ElementRef;
    
    public showDialog: boolean = false;
    public cards: GalleryCards[] = galleryCards;
    public dialogImage: any;

    private subs: Subscription = new Subscription();

    constructor(private renderer: Renderer2, private elem: ElementRef) { }

    public ngAfterViewInit(): void {
        this.subs.add(this.renderer.listen(
            document, 'keydown', event => {
            if (event.key === 'Escape' && this.showDialog) {
                this.showDialog = false;
            }
        }));

        this.subs.add(this.renderer.listen(this.elem.nativeElement, 'click', event => this.onCardClickEvent(event)));
    }

    public ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    public onCardClickEvent(event: any): void {
        const target = event.target;
        
        if (target.className.includes('expand-icon')) {
            const parentElement = this.closest('card-item', target);
            const id = parentElement.getAttribute('id');
            
            
            this.showDialog = true;
            const { src, alt } = this.cards[id];
            this.dialogImage = { src, alt };
        }
    }

    public closest(target, elem): any {
        let found;
        while (!elem.className.includes(target)) {
            found = elem = elem.parentElement;
        }

        return found;
    }
}

