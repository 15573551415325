import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-about-us',
  template: `
    <app-navbar [activeLinkIndex]="2"></app-navbar>
    <div class="container">
      <div class="contact-header">
        <h2 class="desc-header no-underline">Unsere Geschichte</h2>
      </div>
      <div class="our-story">
        <div class="story-containers">
          <div class="our-history">
            <div class="introduction-line">
              <span class="outer-line"></span>
              <img src="../../assets/images/icons/story-icon.png" alt="story icon" />
              <span class="outer-line"></span>
            </div>
            <h2 class="desc-header">{{ getProp('ourHistoryHead') }}</h2>
            <span class="our-history-text"> {{ getProp('ourHistory') }} </span>
          </div>

          <div class="our-workshop">
            <div class="introduction-line">
              <span class="outer-line"></span>
              <img src="../../assets/images/icons/story-workshop.png" alt="story icon" />
              <span class="outer-line"></span>
            </div>
            <h2 class="desc-header">{{ getProp('ourWorkShopHead') }}</h2>
            <span class="our-history-text"> {{ getProp('ourWorkShop') }} </span>
          </div>

          <div class="our-promise">
            <div class="introduction-line">
              <span class="outer-line"></span>
              <img src="../../assets/images/icons/story-promise.png" alt="story icon" />
              <span class="outer-line"></span>
            </div>
            <h2 class="desc-header">{{ getProp('ourPromiseHead') }}</h2>
            <span class="our-history-text"> {{ getProp('ourPromise') }} </span>
          </div>
        </div>
      </div>
      <div class="about-us-info">
        <div class="how-to-find-us">
          <div class="info">
            <h1 class="desc-header no-underline" >Wo Sie uns finden</h1>
            <span>
              <span class="contact-title">Stadt / Land:</span>
              Hamburg, Germany
            </span>
            <span class="address-info">
              <span class="contact-title">Addresse:</span>
              Haldesdorfer Str. 109 22179
              <img
                class="right-arrow-address"
                src="../../assets/images/icons/icons8-advance-100.png"
                alt="right-arrow"
              />
            </span>
            <span>
              <span class="contact-title">Telefon:</span>
              +49 40 55009571
            </span>
            <span>
              <span class="contact-title">Email:</span>
              info@auto-technic.de
            </span>
          </div>
        </div>
        <div class="google-map-container">
          <agm-map [zoom]="15" [latitude]="getProp('lat')" [longitude]="getProp('lng')">
            <agm-marker [latitude]="getProp('lat')" [longitude]="getProp('lng')"></agm-marker>
          </agm-map>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  `,
  styleUrls: ['./about-us.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutUsComponent {
  private lat = 53.6004;
  private lng = 10.0783;

  private introFirstPart = `Ihrer vertrauenswürdigen Kfz-Werkstatt in Hamburg-Bramfeld`;

  private ourHistoryHead = `Wer sind wirrrrr`;
  private ourWorkShopHead = `Unsere Werkstatt`;
  private ourPromiseHead = `Unser Versprechen`;

  private ourHistory = `
    Mit einer Leidenschaft für Autos und einer soliden handwerklichen Ausbildung begann meine Reise
    in die Welt der Kfz-Reparatur. Nachdem ich 10 Jahre lang als Motoreninstandsetzer bei Firma
    Motortechnik in Hamburg-Wandsbek tätig war, wechselte ich zu Firma Fair Car in Hamburg-
    Tonndorf . Hier entwickelte ich meine Fähigkeiten weiter und bereitete mich intensiv auf die
    Meisterprüfung vor, die ich 2015 erfolgreich ablegte.
    Durch diese Erfahrungen und das Verlangen, meine eigene Vision von Kfz-Reparatur und -
    Instandhaltung umzusetzen, eröffnete ich kurz danach meine eigene freie Werkstatt in Hamburg...
  `;

  private ourWorkShop = `
    Unsere Werkstatt ist mit modernster Technik sowie Spezialgeräten und -werkzeugen ausgestattet
    und bietet ein breites Spektrum an Dienstleistungen. Egal, ob es um Fehlerdiagnose, Reparaturen,
    Wartung, Instandsetzung, Unfallinstandsetzung, Achsvermessung, Autoglas etc. geht – wir führen alle
    Arbeiten nach Herstellervorgaben durch, um sicherzustellen, dass Ihr Fahrzeug in bestem Zustand
    bleibt.

    Unsere Spezialisierung liegt auf Fahrzeugen der VAG-Gruppe, darunter VW, Audi, Skoda, Seat sowie
    Porsche. Dennoch sind wir auch für Fahrzeuge anderer Marken, wie Mercedes Benz, BMW, Opel,
    Ford und viele mehr, bestens gerüstet. Unser Engagement für Qualitätsarbeit und höchste Präzision
    bleibt stets konstant.
  `;

  private ourPromise: string = `
    Bei DG Autotechnic garantieren wir:

    Loyalität: Unsere Leidenschaft für Autos spiegelt sich in unserem Service wider. Wir behandeln Ihr
    Fahrzeug, als wäre es unser eigenes.

    Individuelle Beratung und Betreuung: Wir verstehen, dass jedes Fahrzeug und jeder Kunde
    einzigartig ist. Unser Team bietet maßgeschneiderte Lösungen, die auf Ihre Bedürfnisse
    zugeschnitten sind.

    Höchste Qualität und Präzisionsarbeit: Perfektion ist unser Maßstab. Jeder Handgriff wird mit
    größter Sorgfalt und Präzision ausgeführt, um sicherzustellen, dass Ihr Fahrzeug in bestem Zustand
    bleibt.

    Schnelle Terminvergabe: Ihre Zeit ist wertvoll. Wir sind bemüht, Ihnen zeitnahe Termine
    anzubieten, damit Sie schnell wieder sicher auf der Straße unterwegs sein können.

    Optimales Preis-Leistungs-Verhältnis: Wir sind stolz darauf, qualitativ hochwertige Arbeit zu fairen
    Preisen anzubieten. Transparenz ist uns wichtig, und wir besprechen alle Kosten im Voraus.

    Vielen Dank dafür, dass Sie uns Ihr Vertrauen schenken. Wir sind stolz darauf, für unsere Kunden ihre
    vertrauenswürdige Kfz-Werkstatt in Hamburg-Bramfeld zu sein. Unser Team steht Ihnen gerne zur
    Verfügung. Besuchen Sie uns persönlich und lernen Sie uns näher kennen.

    DG Autotechnic - Ihre Partner für eine sichere und zuverlässige Fahrzeugwartung und -reparatur.
  `;

  public getProp(prop: string): string | number {
    return this[prop];
  };

  constructor() {}
}
